.points-input {
  font-size: 14px;
  .error-message {
    background-color: rgba(255, 0, 0, 0.549);
    color: white;
    text-align: center;
    padding: 8px;
  }
  .main-section {
    margin: 30px 0px;
    .main-top {
      padding: 0% 8%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    .main-mid {
      display: flex;
      flex-direction: column;
      p {
        margin: 0;
      }
      .point-form {
        display: flex;
        flex-direction: column;
        input {
          width: 70%;
          height: 37px;
          border-radius: 5px;
          border: 1px solid #C6C6C6;
          margin-top: 5px;
          padding: 0px 10px;
          &:focus-visible {
            outline: none;
          }
        
        }
        
        label {
          margin-left: 4px;
          font-size: 16px;
        }
        hr {
          margin: 10% 0%;
          color: #C6C6C6;
          height: 2px;
        }
        .main-bottom {
          .bottom-points {
            padding: 0% 8%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            p {
              margin: 0;
            }
          }
          .bottom-actions {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }
}
.points-page-caption p {
  text-align: center;
}



@media only screen and (min-width: 1024px) {
  .points-input {
    .main-section {
      .main-mid {
        .point-form {
          input {
            width: 40%;
          }
        }
      }
    }
  }
}