.page-not-found {
  font-size: 14px;
  .card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 16px 20px;
    width: 90%;
    height: 80vh;
    border-radius: 16px;
    .card-body {
      img {
        width: 50%;
        margin: 10% auto 24px;
      }
      h1 {
        margin: 12px auto;
      }
      .back {
        width: max-content;
        font-size: 20px;
        margin: 24px auto;
        color: #4169e1;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
