.points-home {
  font-size: 14px;
  .top-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
    .top-amount {
      font-weight: 600;
      font-size: 40px;
    }
  }
  .history-wrapper {
    margin: 20px 0px;
    .history-list {
      .no-history {
        text-align: center;
        margin: 50px 0px;
        font-weight: bold;
      }
      ul {
        list-style: none;
        padding: 0;
        p {
          margin: 0;
        }
        li {
          .history-item {
            margin: 0% 8%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
            padding: 22px 0px 4px 0px;
            .history-item-transaction {
              display: flex;
              align-items: center;
            }
            .history-item-operator {
              font-size: 18px;
            }
            p {
              margin-left: 4px;
            }
            .history-item-amount {
              font-weight: 600;
              font-size: 24px;
            }
            .history-item-amount-prefix {
              font-size: 24px;
            }
          }
          hr {
            margin: 0;
            color: #C6C6C6;
            height: 2px;
          }
        }
      }
      .see-more {
        display: flex;
        justify-content: flex-end;
        padding: 0% 8%;
        button {
          border: none;
          background: none;
        }
      }
      .pagenation .back{
        padding: .375rem;
        margin-right: 5px;
      }
      .pagenation .next{
        padding: .375rem;
        margin-left: 5px;
      }
      .pagenation .btn{
        padding: unset !important;
      }
      .pagenation{
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .btn.active{
          background-color: var(--color-brand-primary);
          color:white;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          padding: 0;
        }
      }
    }
  }
  .card-body {
    padding: 0;
    .list-group {
      margin-bottom: 24px;
      .list-group-item {
        margin-bottom: 8px;
        padding: 0.5rem 0;
        border-left: none;
        border-right: none;
        border-top: none;
        .value {
          font-weight: 600;
        }
      }
    }
  }
  .page-number button{
    height: 40px;
    margin-right: 5px;
  }
}