.profile-edit {
  .card {
    padding: 6% 8%;
    text-align: left;
    margin: 0 auto 24px;
    border-radius: 16px;
    .card-title {
      margin-bottom: 24px;
      h1 {
        color: var(--color-brand-primary);
        font-size: 24px;
        font-weight: 600;
        width: max-content;
        margin-bottom: 1rem;
      }
      .title-bar {
        width: 150px;
        height: 4px;
        background-color: var(--color-brand-primary);
      }
    }
    .card-body {
      padding: 0;
      form {
        .panel {
          display: none;
        }
        .form-group {
          margin-bottom: 20px;
          label {
            font-weight: 600;
            .required {
              color: red;
              margin: auto 4px;
            }
          }
          input[value] {
            text-align: left;
          }
          .checkbox {
            input {
              margin-right: 6px;
            }
          }
        }
        .action-area {
          display: flex;
          margin: 36px auto 0;
          button {
            background-color: #fff;
            color: var(--color-brand-primary);
            transition: 0.2s all ease-in;
            &:hover {
              background-color: #d0d0d0;
            }
          }
          .back {
            flex-grow: 1;
            text-align: left;
            .btn-back {
              width: 42px;
              height: 42px;
              padding: 0;
              border-radius: 50%;
              border: 1px solid #ccc;
            }
          }
          .submission {
            flex-grow: 1;
            text-align: right;
            .btn-save {
              min-width: 150px;
              font-weight: 600;
              border-radius: 24px;
              padding: 8px 0px;
              background-color: #001233;
              color: #fff;
              &:hover {
                background-color: #001233;
              }
            }
          }
        }
      }
      .no-schema {
        width: max-content;
        padding: 0 16px;
        margin: 24px auto;
        color: #ccc;
        font-size: 24px;
        i {
          margin: auto 12px;
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .profile-edit {
    .card {
      .card-title {
        h1 {
          font-size: 18px;
          margin-bottom: 8px;
        }
        .title-bar {
          width: 100px;
        }
      }
      .card-body {
        font-size: 14px;
        form {
          .form-group {
            input {
              font-size: 14px;
            }
          }
          .action-area {
            .back {
              a {
                .btn-back {
                  width: 37px;
                  height: 37px;
                }
              }
            }
            button {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
