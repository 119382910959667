.error-top {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  background: #ff5454c2;
  color: white;
  padding: 14px;
  text-align: center;
  border-radius: 5px;
  font-size: 11px;
  font-weight: bold;
  margin: auto;
}