.profile-page {
  .card {
    padding: 6% 8%;
    text-align: left;
    margin: 0 auto 24px;
    border-radius: 16px;
    .card-title {
      position: relative;
      margin-bottom: 24px;
      h1 {
        color: var(--color-brand-primary);
        font-size: 24px;
        font-weight: 600;
        width: max-content;
        margin-bottom: 1rem;
      }
      .edit {
        position: absolute;
        right: 0;
        top: 0;
        font-size: 14px;
        font-weight: bold;
        cursor: pointer;
        text-decoration: none;
        color: var(--color-brand-primary);
        i {
          margin-right: 6px;
          font-size: 14px;
        }
      }
      .title-bar {
        width: 150px;
        height: 4px;
        background-color: var(--color-brand-primary);
      }
    }
    .card-body {
      padding: 0;
      .list-group {
        margin-bottom: 24px;
        .list-group-item {
          margin-bottom: 8px;
          padding: 0.5rem 0;
          border-left: none;
          border-right: none;
          border-top: none;
          .value {
            font-weight: 600;
          }
        }
      }
      .no-schema {
        width: max-content;
        padding: 0 16px;
        margin: 24px auto;
        color: #ccc;
        font-size: 24px;
        i {
          margin: auto 12px;
          font-size: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .profile-page {
    .card {
      padding: 5% 20px;
      .card-title {
        h1 {
          font-size: 18px;
          margin-bottom: 8px;
        }
        .title-bar {
          width: 100px;
        }
      }
      .card-body {
        font-size: 14px;
      }
    }
  }
}
