.gray-bar {
  background-color: #EDEDED;
  height: 40px;
  padding: 0% 8%;
  display: flex;
  align-items: center;
  button {
    border: none;
    background: none;
    font-weight: 700;
    i {
      margin-right: 6px;
      font-size: 12px;
    }
  }
  p {
    margin: 0;
    font-weight: 700;
  }
}