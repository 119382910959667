.home-page {
  max-width: 1200px;
  text-align: center;
  background-color: #fff;
  margin: 0 auto;
  padding: 24px;
  border-radius: 16px;
  h1 {
    margin-bottom: 24px;
  }
  .welcome-img {
    width: 500px;
  }
  .divider {
    width: 90%;
    height: 1px;
    background-color: #ddd;
    margin: 16px auto;
  }
  .header-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: max-content;
    max-width: 100%;
    margin: 0 auto;
    background-color: #fff;
    color: #000;
    border-radius: 16px;
    padding: 16px;
    .logo-zone {
      img {
        height: 6vh;
        width: 80%;
        margin-bottom: 16px;
        object-fit: contain;
      }
    }
    .personal-info-zone {
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    .profile-zone {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      // padding: 8px;
      width: 70px;
      height: 70px;
      border-radius: 50%;
      // border: 1px solid #ccc;
      img {
        height: inherit;
      }
      .fa-user {
        color: #aaa;
        font-size: 34px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      img {
        border-radius: 50%;
      }
    }
    .biodata {
      max-width: 85%;
      padding: 8px 24px;
      text-align: left;
      overflow: hidden;
      ul {
        padding: 0;
        margin: 0;
        list-style-type: none;
        li {
          &.name {
            color: var(--color-brand-primary);
            font-weight: 600;
            font-size: 20px;
            margin-bottom: 8px;
          }
          &.email-verified {
            font-size: 14px;
            font-weight: 600;
            &.true {
              color: green;
            }
            &.false {
              color: red;
            }
          }
        }
      }
    }
  }
  .qrcode-area {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 15px;
    .caption {
      font-weight: 600;
    }
    .barcode {
      svg {
        margin: 0px;
      }
    }
    svg {
      margin: 20px 10px;
      max-width: 300px;
    }
    p {
      margin: 0;
      font-weight: 600;
    }
  }
  .customer-info {
    button {
      background: var(--color-brand-primary);
      color: white;
      border: none;
      border-radius: 4px;
      padding: 9px 20px;
      margin-top: 22px;
      font-weight: bold;
    }
  }

  .content-area {
    display: flex;
    max-width: 90%;
    margin: 0 auto;
    .card-link {
      color: #000;
      text-decoration: none;
      cursor: pointer;
      margin: 1rem;
      flex-grow: 1;
      .card {
        background-color: #edeffb;
        border: none;
        border-radius: 8px;
        .card-body {
          padding: 24px 16px;
          .title {
            font-size: 20px;
            font-weight: 600;
            margin-bottom: 8px;
          }
          .subtitle {
            color: #808080;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .home-page {
    padding: 14px;
    .divider {
      display: none;
    }
    .header-area {
      display: none;
    }
    .qrcode-area {
      margin-bottom: 24px;
    }
    .content-area {
      display: block;
      max-width: 100%;
      .card-link {
        .card {
          .card-body {
            padding: 12px 16px;
            .title {
              font-size: 16px;
              margin-bottom: 4px;
            }
            .subtitle {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
