.login-page {
  background: white;
  padding: 6% 8%;
  margin: 0 auto 24px;
  border-radius: 16px;
  font-size: 16px;
  .main-text {
    h1 {
      font-size: 15px;
      font-weight: bold;
    }
    .link-area {
      .mypage-button {
        width: 190px;
        padding: 6px 0px;
        margin: 12px 0px;
        border-radius: 5px;
        border: none;
        background: #001233;
        color: white;
        font-weight: bold;
      }
      .create-ecommerce-button {
        width: 260px;
        height: 3rem;
        padding: 6px 0px;
        margin: 12px 0px;
        border-radius: 5px;
        border: none;
        background: #001233;
        color: white;
        font-weight: bold;
      }
    }
  }
  .form {
    padding-top: 12px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    .login {
      display: flex;
      h1 {
        text-align: left;
        font-size: 16px;
        font-weight: bold;
      }
      .required {
        font-size: 12px;
        font-weight: bold;
        color: rgb(188, 2, 2);
        margin: 0;
      }
    }
    fieldset {
      display: flex;
      flex-direction: column;
      gap: 10px;
      label {
        display: flex;
        flex-direction: column;
        .label-title {
          display: flex;
          align-items: center;
          .required {
            color: rgb(188, 2, 2);
            font-size: 22px;
          }
        }
        input {
          border: 1px solid rgb(197, 197, 197);
          padding: 4px;
        }
        .text-req {
          font-size: 14px;
          margin: 0;
          padding-top: 4px;
          text-align: left;
        }
      }
    }
    .submit-area {
      display: flex;
      flex-direction: column;
      .send-btn {
        padding: 6px;
        width: 120px;
        border: 1px solid rgb(197, 197, 197);
        border-radius: 2px;
        background: rgb(78, 66, 246);
        color: white;
      }
      .send-btn:disabled {
        border: none;
        background: #001233;
      }
    }
    .link-area {
      display: flex;
      flex-direction: column;
      text-align: left;
      font-size: 13px;
      a {
        text-decoration: none;
      }
    }
  }
}