.sidebar-desktop {
  position: relative;
  min-height: 100vh;
  max-width: 350px;
  padding: 24px 0;
  font-size: 15px;
  background-color: #fff;
  transition: 0.5s all ease-in;
  z-index: 1;

  .toggle-icon {
    cursor: pointer;
    width: fit-content;
    position: absolute;
    transition: 0.5s all ease-in;
    z-index: 2;
    opacity: 1;
    &.open {
      i {
        position: fixed;
        color: var(--color-brand-primary);
        font-size: 24px;
      }
    }
    &.close {
      i {
        position: relative;
        color: var(--color-brand-primary);
        font-size: 24px;
      }
    }
  }

  .side-content {
    position: fixed;
    width: 22%;
    max-width: 350px;
    transition: 0.3s all ease-out;
    opacity: 1;
    &.on {
      opacity: 0;
      visibility: hidden;
    }

    .profile {
      padding: 0 24px;
      margin: 24px auto 36px;
      text-align: center;
      .profile-image {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 72px;
        height: 72px;
        margin: 12px auto;
        // padding: 8px;
        border-radius: 50%;
        // border: 1px solid #ccc;
        img {
          width: auto;
          height: 72px;
          border-radius: 50%;
        }
        .fa-user {
          color: #aaa;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          font-size: 36px;
        }
      }
      .username {
        color: var(--color-brand-primary);
        font-weight: 600;
      }
    }
    .menu-list {
      transition: 0.3s all ease-out;
      list-style-type: none;
      padding: 0;
      i {
        margin-right: 12px;
      }
      a {
        text-decoration: none;
        color: #000;
      }
      li {
        cursor: pointer;
        padding: 10px 24px;
        transition: 0.1s all ease-in;
        &.active {
          background-color: #f3f4fe;
          font-weight: 600;
          color: var(--color-brand-primary);
        }
        &:hover {
          background-color: #f3f4fe;
        }
        span {
          transition: 0.2s all ease-in;
        }
        &.logout {
          color: crimson;
        }
      }
    }
  }

  .alt-menu {
    position: fixed;
    width: 19%;
    bottom: 0;
    margin: 24px 0;
    li {
      display: flex;
      align-items: center;
      p {
        margin: 0;
      }
    }
  }
}
.sidebar-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .sidebar-desktop {
    display: none;
  }
  .sidebar-mobile {
    display: block;
    .toggle-mobile {
      position: absolute;
      z-index: 2;
      top: 32px;
      left: 12px;
      i {
        font-size: 24px;
        color: var(--color-brand-primary);
        opacity: 0.8;
        position: relative;
        top: 0;
        left: 10px;
        cursor: pointer;
      }
    }
    .side-content-mobile {
      &::-webkit-scrollbar:vertical {
        height: 0;
        width: 0;
        display: none;
      }
      overflow-x: scroll;
      position: fixed;
      z-index: 2;
      background-color: #fff;
      width: 100%;
      height: 100vh;
      .close {
        position: absolute;
        top: 28px;
        left: 20px;
        i {
          font-size: 32px;
          color: var(--color-brand-primary);
        }
      }
      .profile {
        padding: 0 24px;
        margin: 10% auto 48px;
        text-align: center;
        .profile-image {
          position: relative;
          width: 100px;
          height: 100px;
          margin: 0 auto 16px;
          // padding: 8px;
          border-radius: 50%;
          // border: 1px solid #ccc;
          img {
            width: inherit;
            height: inherit;
            border-radius: 50%;
          }
          .fa-user {
            color: #aaa;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 64px;
          }
        }
        .username {
          font-weight: bold;
          font-size: 20px;
        }
      }
      .menu-list {
        transition: 0.3s all ease-out;
        list-style-type: none;
        padding: 0;
        i {
          margin-right: 12px;
        }
        a {
          text-decoration: none;
          color: #000;
        }
        li {
          cursor: pointer;
          padding: 16px 24px;
          font-size: 16px;
          transition: 0.1s all ease-in;
          text-align: center;
          &.active {
            background-color: #f3f4fe;
            font-weight: 600;
            color: var(--color-brand-primary);
          }
          &:hover {
            background-color: #f3f4fe;
          }
          span {
            transition: 0.2s all ease-in;
          }
        }

        .alt-menu {
          position: relative;
          width: 100%;
          bottom: 0;
          margin: 24px 0;
          div {
            text-align: center;
            padding: 8px;
            font-size: 16px;
            &.logout {
              color: crimson;
            }
          }
        }
      }
    }
  }
}
