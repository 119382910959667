.points-confirm {
  font-size: 14px;
  .main-top {
    margin: 30px 0px;
    padding: 0% 8%;
    .info-amount {
      display: flex;
      flex-direction: column;
      .info-group {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      hr {
        margin: 12% 0%;
        color: #C6C6C6;
        height: 2px;
      }
    }
    .info-sum {
      display: flex;
      justify-content: space-between;
    }
    
  }
  .main-bottom {
    padding-top: 10px;
    .show-staff {
      text-align: center;
    }
    .bottom-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      
    }
  }
}
#root > div > div.main-content > div > div > div:nth-child(2) > div.popup > div > div.bottom-actions > button.cancel-btn {
  background-color: #fff!important;
}