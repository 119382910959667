.withdraw-membership-page {
  .card {
    padding: 6% 8%;
    text-align: left;
    margin: 0 auto 24px;
    border-radius: 16px;
    .card-title {
      margin-bottom: 24px;
      h1 {
        color: var(--color-brand-primary);
        font-size: 24px;
        font-weight: 600;
        width: max-content;
        margin-bottom: 1rem;
      }
      .title-bar {
        width: 150px;
        height: 4px;
        background-color: var(--color-brand-primary);
      }
    }
    .card-body {
      p{
        text-align: left;
      }
      .card-body-cotion{
        text-align: center;
      }
      padding: 0;
      .action-area {
        display: flex;
        align-items: center;
        margin: 36px auto 0;
        .back {
          flex-grow: 1;
          text-align: left;
          .btn-back {
            width: 42px;
            height: 42px;
            padding: 0;
            border-radius: 50%;
            border: 1px solid black;
            &:hover {
              background-color: var(--color-brand-primary);
              color: white;
            }
          }
        }
        .submission {
          width: 80%;
          display: flex;
          justify-content: center;
          .btn-delete {
            width: 90%;
            font-weight: 600;
            border-radius: 4px;
            padding: 12px 24px;
            background-color: white;
            color: black;
            border: 1px solid black;
            &:hover {
              background-color: var(--color-brand-primary);
              color: white;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .withdraw-membership-page {
    .card {
      .card-title {
        h1 {
          font-size: 18px;
          margin-bottom: 8px;
        }
        .title-bar {
          width: 50px;
        }
      }
      .card-body {
        font-size: 14px;
        p {
          font-size: 12px;
        }
        .action-area {
          .back {
            a {
              .btn-back {
                width: 37px;
                height: 37px;
              }
            }
          }
          button {
            font-size: 13px;
          }
        }
      }
    }
  }
}