.actions {
  padding-top: 20px;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-evenly;
  gap: 8px;
  .action {
    width: 48%;
    height: 76px;
    border: 1px solid #80808029;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    color: black;
    text-decoration: none;
    
  }
  
}

.actions a:nth-child(5){
  display: none;
 }
.ecommerce-recomend-caption{
  margin-top: 20px;
    margin-bottom: -30px;
    font-size: 10px;
}
.ecommerce-recomend-caption2 p{
  margin: 0 ;
}
.green-signup {
  width: 100%;
  padding: 2rem 0px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  .link-inner {
    button {
      width: 100%;
      height: 3rem;
      border-radius: 5px;
      border: none;
      background: #001233;
      color: white;
      font-weight: bold;
    }
  }
}