.header-mobile {
  display: none;
}

@media only screen and (max-width: 768px) {
  .main-content {
    .header-mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      border-radius: 16px;
      padding: 16px 24px;
      margin-bottom: 24px;
      .logo-zone {
        img {
          height: 6vh;
          width: 70%;
          margin-bottom: 10px;
          object-fit: contain;
        }
      }
      .personal-info-zone {
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      .profile-zone {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // padding: 8px;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        // border: 1px solid #ccc;
        img {
          height: inherit;
        }
        .fa-user {
          color: #aaa;
          font-size: 26px;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img {
          border-radius: 50%;
        }
      }
      .biodata {
        max-width: 75%;
        padding: 8px 24px;
        text-align: left;
        overflow: hidden;
        ul {
          padding: 0;
          margin: 0;
          list-style-type: none;
          li {
            &.name {
              color: var(--color-brand-primary);
              font-weight: 600;
              font-size: 16px;
              margin-bottom: 8px;
            }
            &.email-verified {
              font-weight: 600;
              &.true {
                color: green;
              }
              &.false {
                color: red;
              }
            }
            &.id {
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
            }
            &.id,
            &.email,
            &.email-verified {
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
