.order-history-page {
  .card {
    padding: 6% 8%;
    text-align: left;
    margin: 0 auto 24px;
    border-radius: 16px;
    .card-title {
      position: relative;
      margin-bottom: 24px;
      h1 {
        color: var(--color-brand-primary);
        font-size: 24px;
        font-weight: 600;
        width: max-content;
        margin-bottom: 1rem;
      }
      .title-bar {
        width: 150px;
        height: 4px;
        background-color: var(--color-brand-primary);
      }
    }
    .card-body {
      padding: 0;
      .show {
        display: block;
      }
      .hide {
        display: none;
      }
      .purchase-group {
        .purchase-group-divider {
          background-color: #EFF5FB;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 6px 12px;
          .location {
            font-size: 13px;
            color: var(--color-brand-primary);
            margin: 0;
          }
          .date {
            font-size: 10px;
            color: #6F7C89;
            margin: 0;
          }
        }
        .purchase {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          border: 1px solid rgba(0,0,0,.125);
          border-top: none;
          border-right: none;
          border-left: none;
          padding: 16px 0px 16px 12px;
          .title {
            font-size: 13px;
            color: var(--color-brand-primary);
          }
          .info {
            font-size: 10px;
            color: #6F7C89;
            min-width: 100%;
            .row {
              margin-bottom: 8px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              gap: 4rem;
              .col {
                width: auto;
                flex: none;
              }
            }
          }
        }
      }
      .pagenation .back{
        padding: .375rem;
        margin-right: 5px;
      }
      .pagenation .next{
        padding: .375rem;
        margin-left: 5px;
      }
      .pagenation .btn{
        padding: unset !important;
      }
      
      .pagenation{
        display: flex;
        justify-content: center;
        margin: 1em;
        .btn.active{
          background-color: var(--color-brand-primary);
          color:white;
          border-radius: 50%;
          width: 40px;
          height: 40px;
        }
      }
    }
  }
  .page-number button{
    height: 40px;
    margin-right: 5px;
  }
}

@media only screen and (max-width: 768px) {
  .order-history-page {
    .card {
      padding: 5% 20px;
      .card-title {
        h1 {
          font-size: 18px;
          margin-bottom: 8px;
        }
        .title-bar {
          width: 80px;
        }
      }
      .card-body {
        font-size: 14px;
      }
    }
  }
}