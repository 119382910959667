.cancel-btn {
  width: 68%;
  // height: 45px;
  text-align: center;
  background: white;
  color: black;
  padding: 10px 30px;
  border-radius: 6px;
  font-weight: 500;
  border: 2px solid black;
  color: black;
  text-decoration: none;
  &:hover {
    color: black;
  }
}

@media only screen and (min-width: 1024px) {
  .cancel-btn {
    width: 40%;
  }
}