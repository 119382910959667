.auth-error {
  font-size: 14px;
  .card {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 16px 20px;
    width: 90%;
    height: 80vh;
    border-radius: 16px;
    .card-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      img {
        width: 40%;
        margin: 1% auto 24px;
      }
      h1 {
        margin: 12px auto;
        font-size: 14px;
      }
      .back {
        width: max-content;
        font-size: 20px;
        margin: 24px auto;
        color: #4169e1;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .auth-error {
    .card {
      .card-body {
        img {
          width: 70%;
        }
      }
    }
  }
}