.main-content {
  text-align: center;
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 24px;
  padding-top: 80px;
  background-color: var(--color-brand-bg);
  box-shadow: 0px 0px 16px 0px #000;
  .upbondlogo {
    cursor: pointer;
    width: 48px;
    margin-bottom: 5%;
  }
  .locales {
    position: absolute;
    top: 24px;
    right: 24px;
    display: flex;
    color: white;
    font-weight: 600;
    font-size: 16px;
    .lang-choice {
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .main-content {
    .locales {
      margin-top: 10px;
      font-size: 13px;
    }
  }
}

.maintenance-content{
  position: relative;
  width: 100%;
  min-height: 100vh;
  padding: 24px;
  padding-top: 80px;
  background-color: var(--color-brand-bg);
  box-shadow: 0px 0px 16px 0px #000;
}