.use-points-btn {
  width: 68%;
  // height: 45px;
  text-align: center;
  background: #001233;
  color: white;
  padding: 10px 30px;
  border-radius: 6px;
  font-weight: 500;
  margin: 20px 0px 10px 0px;
  color: white;
  text-decoration: none;
  &:hover {
    color: white;
  }
}

@media only screen and (min-width: 1024px) {
  .use-points-btn {
    width: 40%;
  }
}