.point-description {
    > p {
        font-size: 14px;
    }
}

.point_table_wrapper{
  
}

.point_table {
    font-size: 11px;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    th {
        border: 1px solid #000;
        background-color: #e9e9e9;
        color: #000;
    }
    td{
        border: 1px solid #000;
    }

}




p {
    text-align: center;
    margin-top: 0.5em;
}