.popup {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 10;
  .popup-inner {
    width: 87%;
    margin-bottom: 130px;
    background: white;
    border-radius: 8px;
    z-index: 20;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 30px 12px;
    .title {
      h1 {
        font-size: 16px;
      }
    }
    .description {
      p {
        font-size: 13px;
      }
    }
    .bottom-actions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      .cancel-btn {
        width: 68%;
        // height: 45px;
        text-align: center;
        background: #001233;
        color: #fff;
        padding: 10px 30px;
        border-radius: 6px;
        font-weight: 500;
        border: 2px solid black;
        color: black;
        text-decoration: none;
        &:hover {
          color: black;
        }
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .popup {
    left: 12%;
    .popup-inner {
      width: 45%;
      .bottom-actions {
        .cancel-btn {
          width: 40%
        }
      }
    }
  }
}